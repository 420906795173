.components-info-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > thead,
  > tbody {
    > tr {
      > th,
      > td {
        border-style: solid;
        border-color: #dee2e6;
        padding: 8px;
      }
    }
  }

  > thead > tr {
    > th:first-child {
      border-top-width: 1px;
      border-right-width: 1px;

      border-radius: 0 0 0 5px;
    }

    > th:last-child {
      border-radius: 5px 0 0 0;
    }
  }

  > tbody:only-child {
    > tr:first-child {
      th:first-child,
      td:first-child {
        border-top-width: 1px;
        border-left-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }

      th:last-child,
      td:last-child {
        border-top-width: 1px;
        border-right-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }
    }

    > tr:last-child {
      th:first-child,
      td:first-child {
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }

      th:last-child,
      td:last-child {
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }
    }

    tr:not(:first-child):not(:last-child) {
      th:first-child,
      td:first-child {
        border-left-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }

      th:last-child,
      td:last-child {
        border-right-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }
    }

    tr:not(:last-child) {
      th,
      td {
        border-bottom-width: 1px;
        border-style: solid;
        border-color: #dee2e6;
      }
    }
  }

  > tbody {
    border-radius: 5px;

    > tr:first-child {
      border-radius: 5px 5px 0 0;

      > th:first-child,
      > td:first-child {
        border-radius: 5px 0 0 0;
      }

      > th:last-child,
      > td:last-child {
        border-radius: 0 5px 0 0;
      }
    }

    > tr:last-child {
      border-radius: 0 5px 5px 0;

      > th:first-child,
      > td:first-child {
        border-radius: 0 0 0 5px;
      }

      > th:last-child,
      > td:last-child {
        border-radius: 0 0 5px 0;
      }
    }

    > tr:nth-of-type(odd) {
      > th,
      > td {
        background-color: #f6f6f6;
      }
    }
  }
}
