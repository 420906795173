.components--layout--dropdown-item {
  display: block;
  padding-right: 15px;
  padding-left: 15px;
  border-right: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
  background: #fff;

  &:link,
  &:visited {
    color: #000;
    text-decoration: none;
  }

  &:active,
  &:hover {
    background: #eaecef;
  }

  &:first-child {
    padding-top: 10px;
    border-radius: 10px 10px 0 0;
    border-top: 1px solid #d2d2d2;
  }

  &:last-child {
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid #d2d2d2;
  }

  &:not(:first-child) {
    padding-top: 4px;
  }

  &:not(:last-child) {
    padding-bottom: 4px;
  }
}
