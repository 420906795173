@import "stylesheets/variables";

.components--layout--nav-item {
  display: block;
  padding-top: 9px;
  padding-bottom: 9px;

  &:link,
  &:visited {
    color: #9b9d9e;
    text-decoration: none;
  }

  &:active,
  &:hover,
  &[data-active="true"] {
    color: #c8c8c9;
  }

  @media (max-width: $sm-to) {
    margin-left: 15px;
  }

  @media (min-width: $md-from) {
    + .components--layout--nav-item,
    + .components--layout--nav-item-dropdown {
      margin-left: 15px;
    }
  }
}
