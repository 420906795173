.components--layout--modal {
  position: fixed;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0 0 0 / 70%);

  .modal-card {
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }
}
