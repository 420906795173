@import "stylesheets/variables";

.components--layout--nav-item-dropdown {
  .nav-item-dropdown-link {
    display: block;
    padding-top: 9px;
    padding-bottom: 9px;

    &:link,
    &:visited {
      color: #9b9d9e;
      text-decoration: none;
    }

    &:active,
    &:hover,
    &[data-active="true"] {
      color: #c8c8c9;
    }
  }

  .nav-item-dropdown-menu {
    position: absolute;
    z-index: 99999;
    top: -5px;
    width: 200px;

    @media (min-width: $md-from) {
      left: -16px;
    }
  }

  @media (max-width: $sm-to) {
    margin-left: 15px;
  }

  @media (min-width: $md-from) {
    + .components--layout--nav-item,
    + .components--layout--nav-item-dropdown {
      margin-left: 15px;
    }
  }
}
